import {useCallback, useMemo} from 'react';
import {styled} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityItem,
  HoobiizOrderItemItem,
  HoobiizStockEntryItem,
  HoobiizStockEntryStatus,
} from '@shared/dynamo_model';
import {NO_ORDER_ITEM_ID} from '@shared/lib/hoobiiz/hoobiiz_ids';

import {apiCall} from '@shared-frontend/api';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AgeString} from '@shared-frontend/components/core/timestamp';
import {Tooltip} from '@shared-frontend/components/core/tooltip';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {FormFlex} from '@src/components/admin/form/form_fragments';
import {Colors} from '@src/components/core/theme_base';
import {DocumentLinkFromFile} from '@src/components/ui/document_link_from_file';
import {ItemSticker} from '@src/components/ui/item_sticker';

interface HoobiizStockEntryFormProps {
  stockEntry: HoobiizStockEntryItem;
  onDelete: (stockEntry: HoobiizStockEntryItem) => void;
  extraInfo?: {activity?: HoobiizActivityItem; order?: HoobiizOrderItemItem};
}

export const HoobiizStockEntryForm: Custom<HoobiizStockEntryFormProps, 'div', 'onChange'> = ({
  stockEntry,
  onDelete,
  extraInfo,
  ...rest
}) => {
  const canDelete = useMemo(() => {
    return stockEntry.orderId === NO_ORDER_ITEM_ID;
  }, [stockEntry.orderId]);

  const handleDeleteClick = useCallback(async () => {
    if (!canDelete) {
      return;
    }
    await apiCall(HoobiizApi, '/admin/delete-stock-entry', {
      stockEntryId: stockEntry.id,
    });
    onDelete(stockEntry);
  }, [stockEntry, onDelete, canDelete]);

  let content = EmptyFragment;
  // if (stockEntry.content?.qrCodes !== undefined) {
  //   content = (
  //     <Input
  //       value={data.code.value}
  //       syncState={handleTicketCodeChange}
  //       overrides={adminInputTheme}
  //       disabled={isDeleted}
  //     />
  //   );
  // }
  if (stockEntry.content?.ticketFileIds !== undefined) {
    content = (
      <div>
        {stockEntry.content.ticketFileIds.map(id => (
          <DocumentLinkFromFile key={id} fileId={id} />
        ))}
      </div>
    );
  }

  // const firstHistoryEvent = stockEntry.history?.[0];
  const lastHistoryEvent = stockEntry.history?.at(-1);

  const orderIdSticker =
    stockEntry.orderId === NO_ORDER_ITEM_ID
      ? {msg: 'En stock', color: Colors.Grey}
      : {msg: 'Acheté', color: Colors.Green};

  const statusSticker = {
    [HoobiizStockEntryStatus.AwaitingUpload]: {msg: 'UPLOAD NÉCESSAIRE', color: Colors.Red},
    [HoobiizStockEntryStatus.AwaitingGeneration]: {
      msg: 'GÉNÉRATION EN COURS',
      color: Colors.Orange,
    },
    [HoobiizStockEntryStatus.Ready]: undefined,
  }[stockEntry.status];

  return (
    <Wrapper {...rest} $isDeleted={false}>
      <Band>
        <SvgIcon name="Ticket" color="#ffffff" size={24} rotate={90} />
      </Band>
      <Content>
        <Title>Ticket</Title>
        {content}
        {statusSticker ? (
          <ItemSticker $color={statusSticker.color}>{statusSticker.msg}</ItemSticker>
        ) : undefined}
        {extraInfo ? (
          <div>
            {extraInfo.activity ? (
              <div>
                Activité :{' '}
                <NavLink to={`/admin/activity/${extraInfo.activity.id}?tab=stock`}>
                  {extraInfo.activity.label}
                </NavLink>
              </div>
            ) : undefined}
            {extraInfo.order ? (
              <div>
                Billets achetés : {extraInfo.order.ticketInfo.label} x{extraInfo.order.quantity}
              </div>
            ) : undefined}
          </div>
        ) : undefined}
      </Content>
      {lastHistoryEvent !== undefined ? (
        <Tooltip
          content={
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Événement</th>
                  <th>Source</th>
                </tr>
              </thead>
              <tbody>
                {stockEntry.history?.map(h => (
                  <tr key={h.ts}>
                    <td>
                      <AgeString ts={h.ts} ago lang="fr-FR" />{' '}
                    </td>
                    <td>{h.event}</td>
                    <td>{h.source}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        >
          <div>
            <AgeString ts={lastHistoryEvent.ts} ago lang="fr-FR" />
          </div>
        </Tooltip>
      ) : undefined}
      <ItemSticker $color={orderIdSticker.color}>{orderIdSticker.msg}</ItemSticker>
      {
        <DeleteButton onClickAsync={handleDeleteClick} disabled={!canDelete}>
          <SvgIcon name="Trash" color="#ffffff" size={24} />
        </DeleteButton>
      }
    </Wrapper>
  );
};
HoobiizStockEntryForm.displayName = 'HoobiizStockEntryForm';

const Wrapper = styled(FormFlex)<{$isDeleted: boolean}>`
  position: relative;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-radius: 8px;
  align-items: center;
  padding: 8px 8px 8px 58px;
  opacity: ${p => (p.$isDeleted ? 1 / 2 : 1)};
`;
const Title = styled.div``;

const Band = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  background-color: ${p => p.theme.main.accentColor};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(Button)`
  height: 48px;
  width: 48px;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const Table = styled.table`
  border-collapse: collapse;
  thead {
    font-weight: 600;
  }
  td,
  th {
    padding: 2px 6px;
  }
`;
