import {FC, Fragment} from 'react';

import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat1ListView} from '@src/components/ui/hoobiiz_cat1_list_view';
import {useIsHoobiiz} from '@src/lib/hoobiiz_context';

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  const {isMobile} = useResponsive();

  return (
    <ColumnWrapper $isMobile={isMobile}>
      <HoobiizCat1ListView />
      {useIsHoobiiz() ? (
        <Fragment>
          <HoobiizActivityModule cse={{mode: 'non-cse-only'}} title="Les dernières offres" />
          <HoobiizActivityModule
            cse={{mode: 'cse-only'}}
            title="Les dernières offres premium"
            hideEmpty
          />
        </Fragment>
      ) : (
        <HoobiizActivityModule
          cse={{mode: 'all', showCheckbox: false}}
          title="Les dernières offres"
        />
      )}
    </ColumnWrapper>
  );
};

HomePage.displayName = 'HomePage';
