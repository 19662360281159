import {FC, JSX, useCallback} from 'react';
import styled, {useTheme} from 'styled-components';

import {HoobiizMediaId} from '@shared/dynamo_model';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {neverHappensSilent} from '@shared/lib/type_utils';

import {Image} from '@shared-frontend/components/core/image';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {hoobiizMediaToImageProps} from '@shared-frontend/lib/hoobiiz_media';
import {useHoobiizMedia} from '@shared-frontend/lib/hoobiiz_media_store';
import {EmptyFragment} from '@shared-frontend/lib/react';

const DISPLAY_SIZE = 200;

export type MediaUploadStatus =
  | {status: 'in-progress'; sourceFile: File}
  | {status: 'success'; id: HoobiizMediaId}
  | {status: 'error'; sourceFile: File; error: string};

interface MediaUploadStatusViewProps {
  uploadStatus: MediaUploadStatus;
  onDeleteClick?: (mediaStatus: MediaUploadStatus) => void;
  onMoveBeforeClick?: (mediaStatus: MediaUploadStatus) => void;
  onMoveAfterClick?: (mediaStatus: MediaUploadStatus) => void;
}

export const MediaUploadStatusView: FC<MediaUploadStatusViewProps> = props => {
  const {uploadStatus, onDeleteClick, onMoveBeforeClick, onMoveAfterClick} = props;
  const {host} = useSsrContext();
  const theme = useTheme();

  // Action handlers
  const handleTrashClicked = useCallback(() => {
    onDeleteClick?.(uploadStatus);
  }, [onDeleteClick, uploadStatus]);

  const handleMoveBeforeClicked = useCallback(() => {
    onMoveBeforeClick?.(uploadStatus);
  }, [onMoveBeforeClick, uploadStatus]);

  const handleMoveAfterClicked = useCallback(() => {
    onMoveAfterClick?.(uploadStatus);
  }, [onMoveAfterClick, uploadStatus]);

  // Load media
  const media = useHoobiizMedia({mediaId: 'id' in uploadStatus ? uploadStatus.id : undefined});

  // Display message
  let message: JSX.Element | undefined;
  if (uploadStatus.status === 'success') {
    if (!media) {
      message = <Message>En traitement</Message>;
    }
  } else if (uploadStatus.status === 'in-progress') {
    message = <Message>Upload en cours</Message>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (uploadStatus.status === 'error') {
    message = <Message>{uploadStatus.error}</Message>;
  } else {
    neverHappensSilent(uploadStatus);
  }

  const imageName = 'sourceFile' in uploadStatus ? uploadStatus.sourceFile.name : '';

  return (
    <Wrapper>
      <MediaWrapper>
        <Image
          {...hoobiizMediaToImageProps(host, media, {
            width: DISPLAY_SIZE,
            height: DISPLAY_SIZE,
          })}
        >
          {message}
        </Image>
      </MediaWrapper>
      <Bottom>
        {onMoveBeforeClick ? (
          <SvgIcon
            onClick={handleMoveBeforeClicked}
            name="ChevronLeft"
            size={12}
            colorHover={theme.main.accentColor}
          />
        ) : (
          EmptyFragment
        )}
        <MediaNameLabel>
          {imageName}
          {onDeleteClick ? (
            <SvgIcon
              onClick={handleTrashClicked}
              name="Trash"
              size={16}
              colorHover={theme.main.accentColor}
            />
          ) : (
            EmptyFragment
          )}
        </MediaNameLabel>
        {onMoveAfterClick ? (
          <SvgIcon
            onClick={handleMoveAfterClicked}
            name="ChevronRight"
            size={12}
            colorHover={theme.main.accentColor}
          />
        ) : (
          EmptyFragment
        )}
      </Bottom>
    </Wrapper>
  );
};
MediaUploadStatusView.displayName = 'MediaUploadStatusView';

const Wrapper = styled.div`
  width: ${DISPLAY_SIZE}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MediaWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #0000002e;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DISPLAY_SIZE}px;
  height: ${DISPLAY_SIZE}px;
  text-align: center;
  padding: 0 16px;
  color: white;
  border-radius: 8px;
  background-color: #00000080;
  box-shadow: 0px 0px 16px -3px #00000059;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MediaNameLabel = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-grow: 1;
`;
