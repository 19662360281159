import {FC, useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {
  HoobiizApi,
  HoobiizStockEntryAttachmentStatus,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizOrderItemId, HoobiizOrderStatus} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {hideModal, showRawModal} from '@shared-frontend/components/core/modal';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {AlertButton} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';
import {HoobiizVendorConfirmModal} from '@src/components/ui/hoobiiz_vendor_confirm_modal';

export const AdminOrderPage: FC = () => {
  const {token = ''} = useParams<{token: string}>();

  const {data, setData} = useApiCall(HoobiizApi, '/get-order-from-token', {token});

  // Auto refresh loop
  const MIN_REFRESH_RATE_MS = 100;
  const MAX_REFRESH_RATE_MS = 5000;
  const refreshRate = useRef(MIN_REFRESH_RATE_MS);
  const stopRefreshLoop = useRef(false);
  const startRefreshLoop = useCallback(
    (orderId: HoobiizOrderItemId) => {
      setTimeout(() => {
        apiCall(HoobiizApi, '/get-order-from-token', {token})
          .then(res => {
            refreshRate.current = Math.min(MAX_REFRESH_RATE_MS, refreshRate.current * 2);
            if (res.order) {
              setData(res);
            }
            const shouldRefresh =
              !res.order ||
              res.order.status === HoobiizOrderStatus.PendingConfirmation ||
              res.order.attachments.some(a => a.status !== HoobiizStockEntryAttachmentStatus.Ready);
            if (!stopRefreshLoop.current && shouldRefresh) {
              startRefreshLoop(orderId);
            }
          })
          .catch(() => {
            refreshRate.current = Math.min(MAX_REFRESH_RATE_MS, refreshRate.current * 2);
          });
      }, refreshRate.current);
    },
    [setData, token]
  );
  useEffect(
    () => () => {
      stopRefreshLoop.current = true;
    },
    [stopRefreshLoop]
  );

  // Finalize the order, update the data, start a refresh loop, and hide the modal
  const finalizeOrder = useCallback(
    async (action: 'confirm' | 'reject') => {
      if (!data?.order) {
        return;
      }
      const orderId = data.order.id;
      await apiCall(HoobiizApi, '/finalize-purchase', {token, orderId, action})
        .then(res => {
          setData(res);
          if (action === 'confirm') {
            startRefreshLoop(orderId);
          }
          hideModal();
        })
        .catch(err => {
          notifyError(err);
          hideModal();
        });
    },
    [data, setData, startRefreshLoop, token]
  );
  const confirmOrder = useCallback(async () => finalizeOrder('confirm'), [finalizeOrder]);
  const rejectOrder = useCallback(async () => finalizeOrder('reject'), [finalizeOrder]);

  // Show confirmation modal for ACCEPT
  const handleConfirmClick = useCallback(async () => {
    return new Promise<void>(resolve => {
      showRawModal({
        mode: 'fade-center',
        children: (
          <HoobiizVendorConfirmModal
            message="Confirmation de la validation de la commande"
            button={<Button onClickAsync={confirmOrder}>Valider la commande</Button>}
          />
        ),
        onHide: resolve,
      });
    });
  }, [confirmOrder]);

  // Show confirmation modal for REJECT
  const handleCancelClick = useCallback(async () => {
    return new Promise<void>(resolve => {
      showRawModal({
        mode: 'fade-center',
        children: (
          <HoobiizVendorConfirmModal
            message="Confirmation du rejet de la commande"
            content={
              <WarningModal>
                Le client ne sera ni notifié par email, ni remboursé.
                <br />
                Merci de contacter le client pour lui proposer une alternative, ou de contacter
                Youpiiz afin de procéder à un remboursement.
              </WarningModal>
            }
            button={<AlertButton onClickAsync={rejectOrder}>Rejeter la commande</AlertButton>}
          />
        ),
        onHide: resolve,
      });
    });
  }, [rejectOrder]);

  if (!data) {
    return (
      <LoadingPage>
        Chargement de la commande
        <LoadingIndicator opacity={0.6} size={64} thickness={5} />
      </LoadingPage>
    );
  }

  if (!data.order) {
    return (
      <LoadingPage>
        <LoadingError>Commande introuvable</LoadingError>
      </LoadingPage>
    );
  }

  return (
    <Wrapper>
      <Title>
        {data.order.endedAt === undefined
          ? EmptyFragment
          : `Acheté le ${new Date(data.order.endedAt).toLocaleString()} par ${
              data.user?.email ?? '"utilisateur inconnu"'
            }`}
      </Title>
      <HoobiizOrderItem orderItem={data.order} />
      {data.order.status === HoobiizOrderStatus.PendingConfirmation ? (
        <Form>
          <Button onClickAsync={handleConfirmClick}>Valider la commande</Button>
          <AlertButton onClickAsync={handleCancelClick}>Rejeter la commande</AlertButton>
        </Form>
      ) : (
        EmptyFragment
      )}
      {data.order.status === HoobiizOrderStatus.Rejected ? (
        <WarningPage>
          Le client n'a pas été automatiquement notifié par email ou remboursé.
          <br />
          Merci de vous assurer d'avoir contacté le client pour lui proposer une alternative, ou
          d'avoir contacté Youpiiz afin de procéder à un remboursement.
        </WarningPage>
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};

AdminOrderPage.displayName = 'AdminOrderPage';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const LoadingPage = styled(Wrapper)`
  font-size: 48px;
  color: ${p => p.theme.main.accentColor};
  text-align: center;
`;

const LoadingError = styled.div`
  font-size: 48px;
  color: ${Colors.Red};
`;

const Title = styled.div`
  font-size: 32px;
  text-align: center;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px 32px;
`;

const Warning = styled.div`
  width: 100%;
  padding: 16px 32px;
  background-color: ${Colors.RedDark};
  color: #ffffff;
  text-align: center;
  line-height: 150%;
`;

const WarningPage = styled(Warning)`
  max-width: 800px;
`;

const WarningModal = styled(Warning)`
  width: 100%;
`;
