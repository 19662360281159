import {HoobiizStockModeType} from '@shared/dynamo_model';

export const HoobiizStockModeTypeLabels: Record<
  HoobiizStockModeType,
  {label: string; subLabel?: string}
> = {
  [HoobiizStockModeType.Pregenerated]: {
    label: `Billet uploadé manuellement, commande confirmée automatiquement`,
    subLabel: `Ex : Billets de cinéma`,
  },
  [HoobiizStockModeType.Automatic]: {
    label: `Billet généré automatiquement, commande confirmée automatiquement`,
    subLabel: `Ex : Bed plage`,
  },
  [HoobiizStockModeType.VendorConfirm]: {
    label: `Billet généré automatiquement, commande confirmée par le partenaire`,
    subLabel: `Ex : Kayak sur mer`,
  },
  [HoobiizStockModeType.AdminConfirm]: {
    label: `Billet généré automatiquement, commande confirmée par l'admin`,
    subLabel: `Va être amené à disparaitre`,
  },
};
