import styled from 'styled-components';

import {
  AddressLookupResult,
  HoobiizMediaId,
  HoobiizVendorId,
  HoobiizVendorLocation,
  Markdown,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {useHoobiizMedias} from '@shared-frontend/lib/hoobiiz_media_store';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizContactAndAccessSection} from '@src/components/ui/hoobiiz_contact_and_access_section';
import {HoobiizMediaGalleryView} from '@src/components/ui/hoobiiz_media_gallery_view';
import {MarkdownRenderer} from '@src/components/ui/markdown';

interface HoobiizVendorPageViewProps {
  vendorId?: HoobiizVendorId;
  name?: string;
  mediaIds?: HoobiizMediaId[];
  info: Markdown;
  locations?: Partial<HoobiizVendorLocation>[];
}

export function addressToLines(address: AddressLookupResult): {line1: string; line2: string} {
  const {streetNumber, route, postalCode, city, country} = address.addressDetails;
  const chunk1 = removeUndefined([streetNumber, route]).join(' ');
  const chunk2 = removeUndefined([postalCode, city]).join(' ');
  const chunk3 = removeUndefined([country]).join(' ');
  return {line1: chunk1, line2: [chunk2, chunk3].filter(s => s.length > 0).join(', ')};
}

export const HoobiizVendorPageView: Custom<HoobiizVendorPageViewProps, 'div'> = ({
  vendorId,
  name,
  mediaIds,
  info,
  locations = [],
  ...rest
}) => {
  const media = useHoobiizMedias({mediaIds: mediaIds ?? []});
  const {isMobile} = useResponsive();

  return (
    <ColumnWrapper $isMobile={isMobile} {...rest}>
      <Header>
        <HeaderLeft>
          <ActivityTitle>{name}</ActivityTitle>
        </HeaderLeft>
        <HeaderRight></HeaderRight>
      </Header>
      <HoobiizMediaGalleryView media={media ?? []} />
      <Content>
        <MarkdownRenderer markdown={info} />
      </Content>
      <Separator />
      <HoobiizContactAndAccessSection locations={locations} />
      {vendorId === undefined ? (
        EmptyFragment
      ) : (
        <HoobiizActivityModule
          vendorId={vendorId}
          cse={{mode: 'all', showCheckbox: true}}
          title={`Les activités proposées par ${name}`}
        />
      )}
    </ColumnWrapper>
  );
};

HoobiizVendorPageView.displayName = 'HoobiizVendorPageView';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ActivityTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin: 22px 0 10px 0;
  background-color: #eeeeee;
`;
