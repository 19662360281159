import {FC, useEffect} from 'react';
import {Navigate, Routes} from 'react-router-dom';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {apiCall} from '@shared-frontend/api';
import {
  cseAdminSessionRequired,
  localhostOnly,
  RouteOpts,
  sessionRequired,
  superAdminSessionRequired,
  useRoute,
} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/core/use_shared_routes';
import {notifyError} from '@shared-frontend/lib/notification';
import {useComponentClass, useWrappedComponentClass} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

import {AdminAccountingPage} from '@src/components/admin/accounting/admin_accounting_page';
import {ActivityCreatePage} from '@src/components/admin/activity/activity_create_page';
import {ActivityDashboard} from '@src/components/admin/activity/activity_dashboard';
import {ActivityPage} from '@src/components/admin/activity/activity_page';
import {HoobiizActivityOfferCreatePage} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_create_page';
import {ActivityExpertTicketStockCreatePage} from '@src/components/admin/activity_stock/activity_expert_ticket_stock_create_page';
import {ActivityStockCreatePage} from '@src/components/admin/activity_stock/activity_stock_create_page';
import {CategoryDashboard} from '@src/components/admin/category/category_dashboard';
import {Category1CreatePage} from '@src/components/admin/category/category1_create_page';
import {Category1UpdatePage} from '@src/components/admin/category/category1_update_page';
import {Category2CreatePage} from '@src/components/admin/category/category2_create_page';
import {Category2UpdatePage} from '@src/components/admin/category/category2_update_page';
import {Category3CreatePage} from '@src/components/admin/category/category3_create_page';
import {Category3UpdatePage} from '@src/components/admin/category/category3_update_page';
import {startDocumentUrlStore} from '@src/components/admin/document_urls_store';
import {ExpertTicketPage} from '@src/components/admin/expert_ticket/expert_ticket_page';
import {AdminExplorerPage} from '@src/components/admin/explorer/admin_explorer_page';
import {AdminOrdersPage} from '@src/components/admin/order/admin_orders_page';
import {AdminUserAndGroupPage} from '@src/components/admin/user_and_group/admin_user_and_group_page';
import {AdminUserAndGroupWhiteLabelingPage} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_page';
import {AdminUsersPage} from '@src/components/admin/user_and_group/admin_users_page';
import {VendorCreatePage} from '@src/components/admin/vendor/vendor_create_page';
import {VendorDashboard} from '@src/components/admin/vendor/vendor_dashboard';
import {VendorUpdatePage} from '@src/components/admin/vendor/vendor_update_page';
import {AdminAppWrapper} from '@src/components/core/admin_app_wrapper';
import {AppWrapper} from '@src/components/core/app_wrapper';
import {CleanAppWrapper} from '@src/components/core/clean_app_wrapper';
import {CseAdminAppWrapper} from '@src/components/core/cse_admin_app_wrapper';
import {CseAdminUsersPage} from '@src/components/cse_admin/users/cse_admin_users_page';
import {AccountPage} from '@src/components/pages/account_page';
import {AdminOrderPage} from '@src/components/pages/admin_order_page';
import {CartPage} from '@src/components/pages/cart_page';
import {ContactPage} from '@src/components/pages/contact_page';
import {CustomerPage} from '@src/components/pages/customer_page';
import {HomePage} from '@src/components/pages/home_page';
import {HoobiizActivityPage} from '@src/components/pages/hoobiiz_activity_page';
import {Cat1Page} from '@src/components/pages/hoobiiz_cat1_page';
import {Cat2Page} from '@src/components/pages/hoobiiz_cat2_page';
import {Cat3Page} from '@src/components/pages/hoobiiz_cat3_page';
import {HoobiizVendorPage} from '@src/components/pages/hoobiiz_vendor_page';
import {IconsPage} from '@src/components/pages/icons_page';
import {NotFoundPage} from '@src/components/pages/not_found_page';
import {OrderPage} from '@src/components/pages/order_page';
import {OrdersPage} from '@src/components/pages/orders_page';
import {PartnerPage} from '@src/components/pages/partner_page';
import {TermsPage} from '@src/components/pages/terms_page';
import {useIsHoobiiz} from '@src/lib/hoobiiz_context';
import {setCartCount} from '@src/lib/stores';

export const App: FC = () => {
  const appSeo = {title: 'Youpiiz', description: 'Youpiiz'};
  const adminSeo = {title: 'Admin', description: 'Admin'};
  const isGendarme = !useIsHoobiiz();
  const session = useSession();

  useEffect(() => {
    if ('sessionId' in session) {
      apiCall(HoobiizApi, '/cart-count', {})
        .then(({count}) => setCartCount(count))
        .catch(err => notifyError(err, {silent: true}));
    } else {
      setCartCount(undefined);
    }
  }, [session]);

  useEffect(() => {
    startDocumentUrlStore();
  }, []);

  const DisabledComponent = useWrappedComponentClass([AppWrapper, NotFoundPage]);
  const RedirectToLoginComponent = useComponentClass(<Navigate to="/login" />);

  const sharedRoutesOpts: RouteOpts = {seo: appSeo, wrapper: AppWrapper};
  const publicAppOpts: RouteOpts = {seo: appSeo, wrapper: AppWrapper};
  // Specific handler for hoobiiz domain names
  const publicForHoobiizOnlyAppOpts: RouteOpts = {
    seo: appSeo,
    wrapper: AppWrapper,
    isDisabledHandler: () => (isGendarme ? DisabledComponent : undefined),
  };
  const localhostOpts: RouteOpts = {
    ...publicAppOpts,
    isDisabledHandler: localhostOnly(DisabledComponent),
  };
  const connectedAppOpts: RouteOpts = {
    ...publicAppOpts,
    isDisabledHandler: sessionRequired(RedirectToLoginComponent),
  };
  const adminOpts: RouteOpts = {
    seo: adminSeo,
    wrapper: AdminAppWrapper,
    isDisabledHandler: superAdminSessionRequired(DisabledComponent),
  };
  const cseAdminOpts: RouteOpts = {
    seo: adminSeo,
    wrapper: CseAdminAppWrapper,
    isDisabledHandler: cseAdminSessionRequired(DisabledComponent),
  };

  return (
    <Routes>
      {/* SHARED ROUTES */}
      {useSharedRoutes(sharedRoutesOpts, {DisabledComponent})}
      {/* ADMIN ROUTES */}
      {useRoute('/admin', useComponentClass(<Navigate to="/admin/orders" replace />), adminOpts)}
      {useRoute('/admin/orders', AdminOrdersPage, adminOpts)}
      {useRoute('/admin/accounting', AdminAccountingPage, adminOpts)}
      {useRoute('/admin/vendor', VendorDashboard, adminOpts)}
      {useRoute('/admin/vendor/create', VendorCreatePage, adminOpts)}
      {useRoute('/admin/vendor/:id', VendorUpdatePage, adminOpts)}
      {useRoute('/admin/cat1', CategoryDashboard, adminOpts)}
      {useRoute('/admin/cat1/create', Category1CreatePage, adminOpts)}
      {useRoute('/admin/cat1/:cat1Id', Category1UpdatePage, adminOpts)}
      {useRoute('/admin/cat1/:cat1Id/cat2/create', Category2CreatePage, adminOpts)}
      {useRoute('/admin/cat1/:cat1Id/cat2/:cat2Id', Category2UpdatePage, adminOpts)}
      {useRoute('/admin/cat1/:cat1Id/cat2/:cat2Id/cat3/create', Category3CreatePage, adminOpts)}
      {useRoute('/admin/cat1/:cat1Id/cat2/:cat2Id/cat3/:cat3Id', Category3UpdatePage, adminOpts)}
      {useRoute('/admin/activity', ActivityDashboard, adminOpts)}
      {useRoute('/admin/activity/create', ActivityCreatePage, adminOpts)}
      {useRoute('/admin/activity/:id', ActivityPage, adminOpts)}
      {useRoute('/admin/activity/:id/stock/create', ActivityStockCreatePage, adminOpts)}
      {useRoute(
        '/admin/activity/:id/expert-ticket-stock/create',
        ActivityExpertTicketStockCreatePage,
        adminOpts
      )}
      {useRoute('/admin/activity/:id/offer/create', HoobiizActivityOfferCreatePage, adminOpts)}
      {useRoute('/admin/expert-ticket', ExpertTicketPage, adminOpts)}
      {useRoute('/admin/explorer', AdminExplorerPage, adminOpts)}
      {useRoute('/admin/groups', AdminUserAndGroupPage, adminOpts)}
      {useRoute('/admin/groups/*', AdminUserAndGroupPage, adminOpts)}
      {useRoute('/admin/users', AdminUsersPage, adminOpts)}
      {useRoute('/admin/theme/:groupId', AdminUserAndGroupWhiteLabelingPage, adminOpts)}
      {useRoute('/admin/*', NotFoundPage, adminOpts)}
      {/* CSE ADMIN ROUTES */}
      {useRoute('/cse-admin/:groupId', CseAdminUsersPage, cseAdminOpts)}
      {useRoute('/cse-admin/:groupId/users', CseAdminUsersPage, cseAdminOpts)}
      {/* PUBLIC PAGES */}
      {useRoute('/', HomePage, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/c1/:slug/:cat1Id', Cat1Page, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/c2/:slug/:cat2Id', Cat2Page, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/c3/:slug/:cat3Id', Cat3Page, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/a/:slug/:id', HoobiizActivityPage, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/v/:slug/:id', HoobiizVendorPage, isGendarme ? connectedAppOpts : publicAppOpts)}
      {useRoute('/terms', TermsPage, publicAppOpts)}
      {useRoute('/contact', ContactPage, publicAppOpts)}
      {useRoute('/devenir-partenaire', PartnerPage, publicForHoobiizOnlyAppOpts)}
      {useRoute('/youpiiz-cest-quoi', CustomerPage, publicForHoobiizOnlyAppOpts)}
      {useRoute('/admin-order/:token', AdminOrderPage, {
        ...publicAppOpts,
        wrapper: CleanAppWrapper,
      })}
      {/* CONNECTED PAGES */}
      {useRoute('/cart', CartPage, connectedAppOpts)}
      {useRoute('/orders/:id', OrderPage, connectedAppOpts)}
      {useRoute('/orders', OrdersPage, connectedAppOpts)}
      {useRoute('/account', AccountPage, connectedAppOpts)}
      {/* LOCALHOST ONLY */}
      {useRoute('/icons', IconsPage, localhostOpts)}
      {/* NOT FOUND */}
      {useRoute('*', NotFoundPage, publicAppOpts)}
    </Routes>
  );
};
App.displayName = 'App';
