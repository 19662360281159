import {FC, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizOrderStatus, HoobiizStockEntryStatus} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizStockEntryForm} from '@src/components/admin/activity_stock/hoobiiz_stock_entry_form';
import {AdminOrdersTable} from '@src/components/admin/order/admin_orders_table';

interface AdminOrdersPageProps {}

type OrderItem = ApiDef<typeof HoobiizApi>['/admin/list-orders']['res']['items'][0];
type StockEntryWithContext = ApiDef<
  typeof HoobiizApi
>['/admin/list-stock-entries-by-status']['res']['data'][0];

export const AdminOrdersPage: FC<AdminOrdersPageProps> = () => {
  const [items, setItems] = useState<OrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingPending, setIsLoadingPending] = useState(false);
  const [pendingItems, setPendingItems] = useState<OrderItem[]>([]);

  const [stockEntries, setStockEntries] = useState<StockEntryWithContext[]>([]);
  const [isLoadingStockEntries, setIsLoadingStockEntries] = useState(false);

  const nextPageToken = useRef<string>();

  const loadOrders = useCallback(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/list-orders', {
      status: HoobiizOrderStatus.Success,
      limit: 100,
      nextPageToken: nextPageToken.current,
    })
      .then(res => {
        setIsLoading(false);
        setItems(current => [...current, ...res.items]);
        nextPageToken.current = res.nextPageToken;
      })
      .catch((err: unknown) => {
        setIsLoading(false);
        notifyError(err, {message: 'Échec du chargement des commandes'});
      });
  }, []);

  const loadPendingOrders = useCallback(() => {
    setIsLoadingPending(true);
    apiCall(HoobiizApi, '/admin/list-orders', {
      status: HoobiizOrderStatus.PendingConfirmation,
      limit: 100,
    })
      .then(res => {
        setIsLoadingPending(false);
        setPendingItems(res.items);
      })
      .catch((err: unknown) => {
        setIsLoadingPending(false);
        notifyError(err, {message: 'Échec du chargement des commandes en cours'});
      });
  }, []);

  const loadStockEntries = useCallback(() => {
    setIsLoadingStockEntries(true);
    apiCall(HoobiizApi, '/admin/list-stock-entries-by-status', {
      status: HoobiizStockEntryStatus.AwaitingUpload,
    })
      .then(res => {
        setIsLoadingStockEntries(false);
        setStockEntries(res.data);
      })
      .catch((err: unknown) => {
        setIsLoadingStockEntries(false);
        notifyError(err, {message: 'Échec du chargement des stocks en attente'});
      });
  }, []);

  useEffect(() => {
    loadOrders();
    loadPendingOrders();
    loadStockEntries();
  }, [loadOrders, loadPendingOrders, loadStockEntries]);

  const handleStockEntryDelete = useCallback(() => {
    notifyError('Suppression non supportée ici');
  }, []);

  return (
    <Wrapper>
      <Section>
        <Title>Commandes en attente de validation</Title>
        {isLoadingPending ? (
          <div>Chargements des commandes...</div>
        ) : (
          <AdminOrdersTable items={pendingItems} />
        )}
      </Section>
      <Section>
        <Title>Stocks en attente d'upload</Title>
        {isLoadingStockEntries ? (
          <div>Chargements des stocks...</div>
        ) : (
          <StockEntriesWrapper>
            {stockEntries.map(({stockEntry, activity, order}) => (
              <HoobiizStockEntryForm
                key={stockEntry.id}
                stockEntry={stockEntry}
                onDelete={handleStockEntryDelete}
                extraInfo={{activity, order}}
              />
            ))}
          </StockEntriesWrapper>
        )}
      </Section>
      <Section>
        <Title>Commandes validées</Title>
        {isLoading ? <div>Chargements des commandes...</div> : <AdminOrdersTable items={items} />}
      </Section>
      {!isLoading && nextPageToken.current === undefined ? (
        EmptyFragment
      ) : (
        <Button
          loading={isLoading}
          disabled={nextPageToken.current === undefined}
          onClick={loadOrders}
        >
          Charger plus de commandes
        </Button>
      )}
    </Wrapper>
  );
};

AdminOrdersPage.displayName = 'AdminOrdersPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StockEntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
