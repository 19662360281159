import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizStockEntryItem,
  HoobiizStockId,
  HoobiizStockItem,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {errorAsString} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showRawModal} from '@shared-frontend/components/core/modal';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizBatchAddStockEntriesForm} from '@src/components/admin/activity_stock/hoobiiz_batch_add_stock_entries_form';
import {HoobiizStockEntryForm} from '@src/components/admin/activity_stock/hoobiiz_stock_entry_form';

interface HoobiizStockEntriesFormProps {
  stockIds?: HoobiizStockId[];
  weeklyTemplateIds?: HoobiizStockWeeklyTemplateId[];
}

export const HoobiizStockEntriesForm: FC<HoobiizStockEntriesFormProps> = ({
  stockIds,
  weeklyTemplateIds,
}) => {
  const [stockEntries, setStockEntries] = useState<HoobiizStockEntryItem[] | undefined>();
  const [stock, setStock] = useState<HoobiizStockItem | undefined>();

  const refreshStockEntries = useCallback(() => {
    setStockEntries(undefined);
    const [stockId] = stockIds ?? [];
    const promise =
      stockId !== undefined
        ? apiCall(HoobiizApi, '/admin/list-stock-entries', {stockId})
        : weeklyTemplateIds !== undefined
          ? apiCall(HoobiizApi, '/admin/list-stock-entries-for-weekly-templates', {
              weeklyTemplateIds,
            })
          : Promise.resolve({stockEntries: []});
    promise
      .then(res => setStockEntries(res.stockEntries))
      .catch(err => {
        notifyError(err, {
          message: `Échec de la récupération des lignes de stock (${errorAsString(err)})`,
        });
      });
  }, [stockIds, weeklyTemplateIds]);

  const refreshStock = useCallback(() => {
    setStock(undefined);
    const [stockId] = stockIds ?? [];
    if (stockId === undefined || stockIds?.length !== 1) {
      return;
    }
    apiCall(HoobiizApi, '/admin/get-stock', {stockId})
      .then(res => setStock(res.stock))
      .catch(err => {
        notifyError(err, {
          message: `Échec de la récupération des infos du stock`,
        });
      });
  }, [stockIds]);

  const refresh = useCallback(() => {
    refreshStock();
    refreshStockEntries();
  }, [refreshStock, refreshStockEntries]);

  useEffect(refresh, [refresh]);

  const handleDeleteStockEntry = useCallback((stockEntry: HoobiizStockEntryItem) => {
    setStockEntries(stockEntries => stockEntries?.filter(e => e.id !== stockEntry.id));
  }, []);

  const handleBatchAddClick = useCallback(() => {
    if (stockIds === undefined) {
      return;
    }
    const [stockId] = stockIds;
    if (stockId === undefined) {
      console.log({stockIds}); // eslint-disable-line no-console
      notifyError(new Error('stockIds is empty'));
      return;
    } else if (stockIds.length > 1) {
      console.log({stockIds}); // eslint-disable-line no-console
      notifyError(new Error('stockIds is not a single stockId'));
      return;
    }
    showRawModal({
      children: <HoobiizBatchAddStockEntriesForm stockId={stockId} />,
      mode: 'slide-right',
      width: '80vw',
      maxWidth: 800,
      onHide: refreshStockEntries,
    });
  }, [stockIds, refreshStockEntries]);

  const sortedStockEntries = useMemo(() => {
    return stockEntries?.sort(
      (a, b) => (b.history?.at(-1)?.ts ?? 0) - (a.history?.at(-1)?.ts ?? 0)
    );
  }, [stockEntries]);

  if (stockIds === undefined || weeklyTemplateIds === undefined) {
    return EmptyFragment;
  }

  if (sortedStockEntries === undefined) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
          {stockIds.length === 1 ? (
            <Button onClick={handleBatchAddClick}>Ajouter des lignes de stocks</Button>
          ) : undefined}
          <Button onClick={refresh}>Actualiser</Button>
        </HeaderLeft>
        <HeaderRight></HeaderRight>
      </Header>
      {stock !== undefined ? (
        <TicketQuantitiesTable>
          <thead>
            <tr>
              <th>Billets Disponibles</th>
              <th>Billets Achetés</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{stock.remaining}</td>
              <td>{`${stock.bought}${stock.reserved > 0 ? ` (+${stock.reserved})` : ''}`}</td>
            </tr>
          </tbody>
        </TicketQuantitiesTable>
      ) : undefined}
      <StockEntryList>
        {sortedStockEntries.map(e => (
          <HoobiizStockEntryForm key={e.id} stockEntry={e} onDelete={handleDeleteStockEntry} />
        ))}
      </StockEntryList>
    </Wrapper>
  );
};
HoobiizStockEntriesForm.displayName = 'HoobiizStockEntriesForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StockEntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

const HeaderLeft = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderRight = styled.div``;

const TicketQuantitiesTable = styled.table`
  border-collapse: collapse;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-top: none;
  width: max-content;

  & > tbody > tr {
    background-color: transparent;
    &:nth-child(even) {
      background-color: #00000011;
    }
    &:hover {
      cursor: pointer;
      background-color: #00000022;
    }
  }

  & > thead > tr > th {
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    background: ${p => p.theme.main.accentColor};
    color: #ffffff;
    vertical-align: middle;
    text-align: center;
  }

  & > tbody > tr > td {
    padding: 6px 12px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
  }
`;
